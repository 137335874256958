.feedback-buttons {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 450px;
    padding: 0px 0px;
}

.feedback-fab {
    box-shadow: none !important;
    background-color: rgba(47, 47, 47, 0.517) !important;
    height: 35px !important;
    width: 35px !important;
    margin-left: 3px !important;
    border: solid 1px #82828280 !important;
}

.call-button {
    box-shadow: none !important;
    background-color: rgba(47, 47, 47, 0.517) !important;
    border: 1px solid #82828280 !important;
    color: white !important;
    width: 150px;
    font-size: 10px !important;
    padding: 13px 5px !important;
    border-radius: 25px !important;
}

.feedback-icons {
    height: 15px !important;
    color: rgba(240, 248, 255, 0.553);
}

.feedback-fab.selected {
    background-color: rgba(255, 253, 247, 0.079) !important;
}

.feedback-fab.selected .feedback-icons {
    color: rgb(255, 255, 255) !important;
    animation-name: teeter;
    animation-duration: 0.1s;
    animation-iteration-count: 5;
    animation-timing-function: ease-in-out;
}

.animationfb {
    position: absolute !important;
    height: 200px;
    width: 200px;
}

.gradients {
    pointer-events: none;
    position: absolute;
    width: 100%;
    min-height: 100%;
    z-index: 2;
    margin-top: 100px;
    background: linear-gradient(180deg, rgb(71 85 90) 20%, rgba(68, 68, 68, 0) 25%, hwb(197 28% 65% / 0) 100%);
}


.message-list {
    margin-top: 15px !important;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 800px;
    flex-direction: column-reverse;
}



.rce-mbox .left {
    background: rgba(255, 255, 255, 0);
    border-top-left-radius: 0;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0);
    flex-direction: column;
    float: left;
    min-width: 140px;
    padding: 5px 0px !important;
    position: relative;
    font-size: 25px !important;
    animation-duration: 2.3s;
    animation-name: animate-fade;
    animation-delay: 0.5s;
    font-weight: 500;
    animation-fill-mode: backwards;
    z-index: 10;
    border-color: #e2e2e229;
    border-width: 0px 0px 1px 0px;
    border-style: solid;
}

.rce-mbox .right {
    pointer-events: auto;
    max-height: 350px !important;
    overflow: scroll !important;
    overflow-x: hidden !important;
    background: rgba(255, 255, 255, 0);
    border-radius: 5px;
    border-top-left-radius: 0;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0);
    flex-direction: column;
    float: left !important;
    margin: 0px !important;
    padding: 5px 0px !important;
    min-width: 140px;
    padding: 6px 9px 8px;
    position: relative;
    animation-duration: 2.0s;
    animation-name: animate-fade;
    animation-delay: 0.8s;
    animation-fill-mode: backwards;
}

.rce-mbox-photo--img img {
    /* margin-bottom: -68px !important; */
    width: 100% !important;
    z-index: 2;
    /* margin-top: 50px; */
    height: 100px !important;
    object-fit: cover;
    animation-duration: 2.0s;
    animation-name: animate-fade;
    animation-delay: 0.3s;
    animation-fill-mode: backwards;
    max-width: 800px;
}

@keyframes animate-fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.rce-mbox-text {
    font-size: 18px !important;
    word-break: break-word;
    color: #5e5e5e;
}

.rce-mbox.rce-mbox-right {
    border-top-left-radius: 5px;
    border-top-right-radius: 0;
    float: left !important;
    margin-left: 0px !important;
    margin-right: 20px;
}

.rce-mbox {
    background: rgba(255, 255, 255, 0) !important;
    border-radius: 0px !important;
    border-top-left-radius: 0 !important;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0) !important;
    flex-direction: column !important;
    float: none !important;
    margin: 0px !important;
    min-width: 140px !important;
    padding: 0px !important;
    position: inherit !important;
}

.rce-mlist {
    overflow: hidden !important;
}

.rce-container-mlist {
    margin-top: 10px;
    max-height: 60vh;
    display: block !important;
    position: relative;
    overflow: scroll;
}

@media only screen and (max-height: 570px) {
    .rce-container-mlist {
        max-height: 50vh;
    }

    .msg-container {
        max-height: 95%;
    }
}

@keyframes teeter {

    0% {}

    33% {
        transform: translate(-7%, 0) rotate(-9deg);
    }

    66% {
        transform: translate(-5%, 0) rotate(4deg);
    }

    66% {
        transform: translate(7%, 0) rotate(9deg);
    }
}

.css-3bmhjh-MuiPaper-root-MuiPopover-paper,
.css-1dmzujt {
    display: flex;
    padding: 10px;
    flex-direction: column;
}

.feedback-dash .MuiOutlinedInput-input {
    background-color: white !important;
    color: black !important;
}

.feedback-dash .Mui-focused {
    color: black !important;
    border-color: black !important;
}

/* width */
::-webkit-scrollbar {
    width: 2px;
    color: rgba(255, 0, 0, 0.084);
}

::-webkit-scrollbar-corner {
    width: 2px;
    color: rgba(255, 0, 0, 0.016);
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 1px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 10px;
}

.rce-mbox-left-notch,
.rce-mbox-right-notch {
    display: None;
}

.msg-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.msg-container div,p{
    white-space: pre-wrap;
    margin-bottom: 0px !important;
}

.msg-container div{
    display: flex;
    padding-top: 0px !important;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.react-quiz-container .questionWrapper .btn {
    border: 1px solid #000000 !important;
}

.react-quiz-container {
    background-color: transparent !important;
}

.nextQuestionBtn {
background-color: transparent !important;
color: rgb(0, 0, 0);
}

h3{
    font-weight: bold;
}

.react-quiz-container .result-answer-wrapper h3 {
    background-color: transparent !important;
}