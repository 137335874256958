.css-q44vsa {
  color: white !important;
}

.css-t2b9rp {
  color: white !important;
}

.css-ffvrb6 {
  width: 100%;
}

.css-14x1nlk {
  color: white !important;
}

.css-j7qwjs {
  width: 100%;
}

.list {
  pointer-events: none;
  overflow: overlay;
  align-self: center !important;
  justify-self: center;
  position: absolute;
  align-content: flex-end;
  display: grid;
  height: 100%;
  width: 100%;
  z-index: 2;
  justify-items: center;
}

.controls-cta::-webkit-scrollbar {
  width: 5px;
  height: 1px;
  /*background-color: rgba(253, 253, 253, 0.315);  or add it to the track */
}

.controls-cta::-webkit-scrollbar-thumb {
  background-color: rgb(228, 35, 19);
  /* or add it to the track */
}

.alert-info {
  position: absolute;
  width: 100%;
  z-index: 10;
}

.css-4mzek5-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}

.buttons-list {
  width: 100%;
  padding: 30px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center !important;
  justify-self: center;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 700;
  text-shadow: 0 0 1px #000;
  z-index: 10;
}

.controls-cta {
  pointer-events: auto;
  max-height: 300px;
  overflow: scroll;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 800px;
  margin: 0 auto;
}

.buttons {
  font-family: Gilroy-Light !important;
  pointer-events: auto;
  cursor: pointer;
  background-color: rgba(10, 10, 10, 0.2) !important;
  border: 1px solid rgba(255, 255, 255, 0.4) !important;
  box-sizing: border-box !important;
  border-radius: 60px !important;
  padding: 0px 12px;
  color: white !important;
  transition: opacity 0.3s ease 0s;
  min-width: 100%;
  width: 100%;
  font-size: 17px !important;
  flex: 1% 1% 1%;
  display: flex;
  -webkit-box-align: left;
  align-items: left;
  min-height: 52px;
  margin: 5px 5px !important;
  text-align: left !important;
  justify-content: left !important;
  text-transform: initial !important;
}

@media only screen and (max-width: 430px) {
  .buttons {
    white-space: normal;
    width: 100%;
    max-width: 100%;
    justify-items: stretch;
    margin-left: 1px;
    margin-right: 1px;
  }
}

@media only screen and (min-width: 1080px) {
  .buttons {
    font-size: 30px !important;
  }

  .title {
    font-size: 50px;
  }

  .num {
    min-width: 55px;
    max-height: 55px;
    margin-right: 10px !important;
    text-align: center !important;
  }

  .css-10botns-MuiInputBase-input-MuiFilledInput-input {
    font-size: 25px !important;
  }

  .css-o943dk-MuiFormLabel-root-MuiInputLabel-root {
    color: white;
    font-size: 20px !important;
  }

  .css-o943dk-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: white !important;
    font-size: 20px !important;
  }

  .css-e4w4as-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 20px !important;
  }

  .controls-cta {
    max-height: 500px;
  }

}


.css-o943dk-MuiFormLabel-root-MuiInputLabel-root {
  color: white !important;
}

.css-o943dk-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: white !important;
}

.css-2bxn45 {
  color: white !important;
}

.css-1rgmeur {
  color: white !important;
}


.css-2bxn45 {
  color: white !important;
}

.css-1rgmeur {
  color: white !important;
}

/* animations effects  */
.fade-enter {
  opacity: .01;
}

.fade-enter.fade-enter-active {
  opacity: .5;
  transition: opacity 1000ms ease-in;
}

.fade-leave {
  opacity: .01;
}

.fade-leave.fade-leave-active {
  opacity: .5;
  transition: opacity 1000ms ease-in;
}

.spicy-enter {
  opacity: .01;
  transform: scaleX(0) translateY(100px);
}

.spicy-enter.spicy-enter-active {
  opacity: 1;
  transform: scaleX(1) translateY(0);
  transition: all 300ms ease-in-out;
}

.spicy-leave {
  opacity: 1;
  transform: scaleX(1) translateY(0);
}

.spicy-leave-active {
  opacity: 01;
  transform: scaleX(0) translateY(100px);
  transition: all 1000ms ease-in-out
}

/* Text imput Style */
.css-e4w4as-MuiFormLabel-root-MuiInputLabel-root {
  color: rgb(255 255 255) !important;
  pointer-events: auto;
}

.css-cio0x1-MuiInputBase-root-MuiFilledInput-root:hover {
  background-color: rgba(0, 0, 0, 0.815) !important;
}

.css-10botns-MuiInputBase-input-MuiFilledInput-input {
  color: white !important;
  font-size: 25px;
}


/* Radio imput Style */
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: white;
}

.css-ahj2mt-MuiTypography-root {
  color: white;
}

.css-dmmspl-MuiFormGroup-root {
  background-color: rgba(0, 0, 0, 0.815) !important;
  padding: 20px;
}

.css-nen11g-MuiStack-root {
  width: 100%;
}

.num {
  background-color: rgba(250, 4, 4, 0.76);
  border-color: white;
  border-width: 3px;
  border-radius: 50%;
  padding: 2px 13px !important;
  margin-right: 10px;
}

.inside-button {
  display: inline-flex;
  align-items: center;
}

.buttons span {
  pointer-events: none;
  text-align: left;
}

/* var*/
.css-cio0x1-MuiInputBase-root-MuiFilledInput-root:after {
  border-bottom: 2px solid #ffffff !important;
}

.css-au3a9q-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: white !important;
}

.css-10botns-MuiInputBase-input-MuiFilledInput-input {
  -wap-input-format: 'N';
}