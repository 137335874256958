@font-face {
  font-family: 'Baskerville';
  src: local('Baskerville'), url(../fonts/baskvl.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

body {
  font-family: Baskerville !important;
}

.fade-in {
  animation-duration: 1.0s;
  animation-name: animate-fade-in;
  animation-delay: 0s;
  animation-fill-mode: backwards;
}

.fade-out {
  animation-duration: 1.0s;
  animation-name: animate-fade-out;
  animation-delay: 0s;
  animation-fill-mode: backwards;
}

@keyframes animate-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animate-fade-out {
  100% {
    opacity: 0;
  }

  0% {
    opacity: 1;
  }
}

.MuiButton-containedPrimary {
  color: #fff;
  background-color: #e91c1c !important;
  text-transform: capitalize !important;
}

.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
  z-index: 120000 !important;
}

.tooltip-ct {
  background-color: white;
  margin: 0px 0px;
  border-radius: 5px;
  padding: 10px 5px;
}

.tooltip-close {
  float: right;
  margin-right: -20px !important;
  margin-top: -10px !important;
  color: black !important;
  border: solid 1px black;
  border-radius: 50%;
}