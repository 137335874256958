.mic-button {
    pointer-events: all;
}

.questions {
    line-height: 1;
    font-size: 20px;
    margin-top: 15px;
}

@media only screen and (min-width: 1080px) {

    .css-oma7rc-MuiAvatar-root {
        min-width: 300px;
        min-height: 300px;
    }

    .css-1pqm26d-MuiAvatar-img {
        width: 180% !important;
        height: 180% !important;
        padding-top: 30px;
    }
}

.css-1pqm26d-MuiAvatar-img {
    width: 100% !important;
    height: 100% !important;
}

.buttons-actions {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    align-items: flex-end;
    justify-content: center;
    pointer-events: none;
}

.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 2s, opacity 2s linear;
}

.replay-audio {
    height: 51px !important;
    width: 51px !important;
    pointer-events: all;
    color: rgb(228, 228, 228);
    border: rgb(224, 223, 223) solid 1px;
    background-color: rgba(39, 39, 39, 0.695);
    border-radius: 50%;
    margin-right: 15%;
    margin-bottom: 37px;
}

.pause-audio {
    height: 51px !important;
    width: 51px !important;
    pointer-events: all;
    color: rgb(228, 228, 228);
    border: rgb(224, 223, 223) solid 1px;
    background-color: rgba(39, 39, 39, 0.695);
    border-radius: 50%;
    margin-left: 15%;
    margin-bottom: 37px;
}

.close-audio {
    position: absolute !important;
    color: rgb(62, 62, 62) !important;
    margin-left: 90% !important;
    font-size: 20px !important;
    margin-top: 2% !important;
    z-index: 5;
    width: auto;
}


.close-audio span {
    padding: 4.5px;
}

.loading {
    position: absolute !important;
    height: 200px !important;
    width: 200px !important;
    pointer-events: all;
}

.rce-mbox-text:after {
    display: none;
}

.sound-animation{
    width: 200px !important;
    height: 50px !important;
    margin-top: 0px !important;
}

.msg-container div {
    width: 100%;
}

.react-quiz-container .tag-container {
    display: none;
}

.container {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
    
    border-radius: 3px;
  }
  
  @media (min-width: 769px) {
    .container {
      margin: 2.5rem auto;
    }
  }
  
  .nav-bar {
    text-align: center;
  }
  
  .react-quiz-container {
    background-color: #ffffffba;
    margin: 0 auto !important;
    padding: 20px;
    text-align: center;
    width: 100% !important;
    border-radius: 10px;
}

  .react-quiz-container .result-answer-wrapper {
    margin-bottom: 20px !important;
    border: 1px solid #4c4c4c !important;
    background: transparent;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.react-quiz-container .result-answer-wrapper .explanation {
    padding: 20px;
     margin: 0px !important;
    border: 1px solid #e8e8e8;
}

.react-quiz-container .btn {
    margin-bottom: 0;
    font-weight: 200 !important;
}

.react-quiz-container .quiz-result-filter {
    margin-bottom: 10px !important;
}

.react-quiz-container .questionWrapper .btn {
    font-size: 18px !important;
}