@keyframes animate-fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.account-settings {
    display: flex !important;
    align-items: center;
    justify-items: center;
    justify-content: flex-start;
    background-color: #faf5ea;
    flex-direction: column;
}

.avatar-name {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
    background-color: rgba(120, 120, 120, 0.208);
    width: 100%;
    padding-top: 50px;
    text-transform: uppercase;
}

.avatar-name p {
    color: rgba(0, 0, 0, 0.692) !important;
    text-transform: capitalize;
    font-size: 1.4em;
}

.account-settings p {
    color: rgb(255, 255, 255);
}

.account-settings p span {
    color: rgb(39, 39, 39);
    text-decoration: underline;
}

.account-settings .settings-form {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-gap: 20px;
    padding: 20px;
    border-radius: 5px;
}

.settings-form label span {
    color: rgb(39, 39, 39);
}

.settings-form label {
    color: rgb(39, 39, 39);
    margin-top: 10px;
}


.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
    background-color: #19d21c !important;
}

.settings-form .css-dmmspl-MuiFormGroup-root {
    background-color: #1515151b !important;
    padding: 20px;
    border-radius: 10px;
}

.settings-form .MuiOutlinedInput-input {
    animation-duration: 1.0s;
    animation-name: animate-fade;
    animation-delay: 0.5s;
    animation-fill-mode: None;
    color: rgb(94, 94, 94) !important;
    background-color: #22222219 !important;
    border-radius: 5px;
    padding: 5px;
}

.save-button {
    background-color: #00000050 !important;
    border-radius: 25px !important;
    border-style: solid !important;
    margin-bottom: 20px !important;
    border-color: rgb(255, 255, 255) !important;
    border-width: 1px !important;
}

.save-button:hover {
    background-color: #ffffff !important;
    color: black !important;
}