.micControl {
    position: absolute !important;
    z-index: 4000;
    height: 75px !important;
    width: 75px !important;
    pointer-events: all;
}

.animation-container {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    pointer-events: none;
    flex-direction: row;
    align-content: center;
    justify-content: center;
}

.micControl-animation {
    margin-bottom: 50px !important;
}

.micControl-icon {
    height: 51px !important;
    width: 51px !important;
    pointer-events: all;
    color: rgb(228, 228, 228);
    border: rgb(224, 223, 223) solid 1px;
    background-color: rgba(39, 39, 39, 0.695);
    border-radius: 50%;
    margin-bottom: 16px;
}

.container-icon {
    position: absolute !important;
    z-index: 1000;
    width: 100% !important;
    display: flex;
    align-items: flex-end;
    pointer-events: none;
    flex-direction: row;
    align-content: center;
    justify-content: center;
}

@media only screen and (min-width: 1080px) {
    .micControl-icon {
        height: 90px !important;
        width: 90px !important;
        pointer-events: all;
        color: rgb(228, 228, 228);
        border: rgb(224, 223, 223) solid 1px;
        background-color: rgba(39, 39, 39, 50%);
        border-radius: 50%;
        margin-bottom: 10px;
    }

    .micControl {
        position: absolute !important;
        z-index: 3000;
        height: 120px !important;
        width: 120px !important;
        pointer-events: all;
    }

    .micControl-animation {
        height: 120px !important;
        width: 120px !important;
    }

}