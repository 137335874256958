.test-icon {
    position: absolute !important;
    color: #fffefee3 !important;
    background: #0000004f !important;
    border-color: rgba(255, 255, 255, 0.795) !important;
    margin-left: 85%;
    margin-top: 5%;
    z-index: 100;
    border-radius: 50%;
    box-shadow: #00000078 1px 1px 3px;
    font-size: 3rem !important;
    padding: 4px;
    overflow: visible;
    border-width: 2px;
}

.test-icon:hover {
    position: absolute !important;
    color: #ffffff !important;
}

.test-icon-container {
    background-color: rgba(7, 7, 7, 0.46);
}

.test-icon-icon {
    margin: 10px !important;
}

.home {
    position: absolute !important;
    color: #fffefee3 !important;
    background: #0000004f !important;
    border-color: rgba(255, 255, 255, 0.795) !important;
    margin-left: 85%;
    margin-top: 40.5%;
    z-index: 1000;
    border-radius: 50%;
    box-shadow: #0000005a 1px 1px 3px;
    font-size: 3rem !important;
    overflow: visible;
    border-width: 2px;
    padding: 4px;
}

.login-icon {
    position: absolute !important;
    color: #fffefee3 !important;
    background: #0000004f !important;
    border-color: rgba(255, 255, 255, 0.795) !important;
    margin-left: 85%;
    margin-top: 8%;
    z-index: 1000;
    border-radius: 50%;
    box-shadow: #0000005a 1px 1px 3px;
    font-size: 3rem !important;
    padding: 4px;
    overflow: visible;
    border-width: 2px;

}

@media only screen and (max-width: 430px) {
    .video {
        height: 100% !important;
        -o-object-fit: cover !important;
        object-fit: cover !important;
        overflow: hidden;
    }


    .home {
        margin-left: 85% !important;
        margin-top: 66% !important;

    }

    .login-icon {
        margin-left: 85% !important;
        margin-top: 20% !important;

    }

    .test-icon {
        margin-left: 85% !important;
        margin-top: 18% !important;
    }

    .icon-span {
        margin-left: 80% !important;
        margin-top: 28.5% !important;
    }
}

@media only screen and (min-width:480px) {

    .home {
        margin-left: 85% !important;
        margin-top: 43% !important;
    }

    .login-icon {
        margin-left: 85% !important;
        margin-top: 12% !important;

    }

    .test-icon {
        margin-left: 85% !important;
        margin-top: 12% !important;
    }

    .icon-span {
        margin-left: 83% !important;
        margin-top: 17.5% !important;
    }
}

@media only screen and (min-width:720px) {

    .home {
        margin-left: 85% !important;
        margin-top: 35% !important;
    }

    .test-icon {
        margin-left: 85% !important;
        margin-top: 11% !important;
    }

    .icon-span {
        margin-left: 78% !important;
        margin-top: 12.4% !important;
    }
}

@media only screen and (min-width:1200px) {

    .home {
        margin-left: 85% !important;
        margin-top: 20% !important;
    }

    .test-icon {
        margin-left: 85% !important;
        margin-top: 8% !important;
    }

    .icon-span {
        margin-left: 81% !important;
        margin-top: 8.9% !important;
    }

    .login-icon {
        margin-left: 85% !important;
        margin-top: 8% !important;
    }
}

.icon-span {
    position: absolute;
    z-index: 10000;
    background-color: red;
    color: white;
    border-radius: 25px;
    font-size: 0.6em;
    padding: 0px 5px;
}